import React, { useEffect, useMemo, useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuGroup,
  MenuAlign,
  MenuDirection,
} from '@szhsin/react-menu'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import ButtonWithConfirmation, { ConfirmType } from '@components/Button/ButtonWithConfirmation'
import CompanyItem from '@components/CompanyItem'
import { IconChevronRight, IconPencil, IconSquareRoundedPlus } from '@tabler/icons-react'

import { useGetApproveitOrganizations } from '@queries/Accounting'
import { useStore } from '@store'
import { ORGANIZATION_MENU } from '../menuItems'
import routes from '@src/Routes/routes'

import { filterNavItems } from '../utils'
import useGetAccount from '@helpers/hooks/useGetAccount'
import { useSwitchActiveOrganization } from '@helpers/hooks/useSwitchActiveOrganization'
import { amber, emerald, indigo, neutral, orange, pink, red, rose, sky, teal } from 'tailwindcss/colors'

import { Role } from '@type/common'
import { OrganizationListItem } from '@models/ApproveitAccounting'

import styles from './index.module.css'
import { useGetHiddenWithPaywallFeatures } from '@src/helpers/hooks/useGetHideWithPaywall'
import { twMerge } from 'tailwind-merge'

const COLORS = [
  indigo[400],
  emerald[400],
  teal[400],
  pink[400],
  rose[400],
  red[400],
  orange[400],
  amber[400],
  sky[400],
  neutral[400],
]

export interface OrganizationMenuProps {
  isBurgerMenu?: boolean
  classes?: {
    companyItem?: string
    menu?: string
    button?: string
  }
  align?: MenuAlign
  direction?: MenuDirection
}

export const OrganizationMenu: React.FC<OrganizationMenuProps> = ({
  isBurgerMenu = false,
  classes = {},
  align = 'start',
  direction = 'right',
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' })
  const navigate = useNavigate()
  const params = useParams<{ pipelineId: string; processId: string; stageId: string }>()
  const user = useGetAccount()

  const { acc_payable: accountPayableIsHidden, acc_receivable: accountReceivableIsHidden } =
    useGetHiddenWithPaywallFeatures()
  const financialFeaturesIsAvailable = !accountPayableIsHidden?.hidden || !accountReceivableIsHidden?.hidden

  const [orgIsSet, setOrgIsSet] = useState(false)

  const isAdmin = user?.role === Role.admin

  const { data: organizations } = useGetApproveitOrganizations()
  const organization = useStore.use.organization()

  const switchOrganization = useSwitchActiveOrganization()

  useEffect(() => {
    const userOrganizationID = user?.settings?.active_organization
    const noOrgSelected = !userOrganizationID && !organization
    const selectedOrgsIsNotEqual = userOrganizationID && organization !== userOrganizationID
    if (organizations?.length && !orgIsSet) {
      let selectedOrg = null

      if (noOrgSelected) {
        selectedOrg = organizations[0]
      }
      if (selectedOrgsIsNotEqual && userOrganizationID) {
        selectedOrg = organizations?.find((org) => org.id === userOrganizationID)
      }
      if (selectedOrg) {
        switchOrganization(selectedOrg)
      }
      setOrgIsSet(true)
    }
  }, [switchOrganization, organizations, organization, user, orgIsSet])

  const org = organizations?.find((el) => el.id === organization)

  const organizationMenuItems = useMemo(() => {
    return filterNavItems(ORGANIZATION_MENU, user)
  }, [])

  const isStartPipelineRoute =
    !!params.pipelineId &&
    location.pathname.includes(generatePath(routes.private.pipelineApprovalRequest, { pipelineId: params.pipelineId }))
  const isStartStageRoute =
    !!params.processId &&
    !!params.stageId &&
    location.pathname.includes(
      generatePath(routes.private.pipelineProcessApprovalRequest, {
        processId: params.processId,
        stageId: params.stageId,
      }),
    )

  const onOrganizationSwitch = (org: OrganizationListItem) => {
    return () => {
      switchOrganization(org)
    }
  }
  const showAddOrganizationButton = isAdmin && financialFeaturesIsAvailable
  const showOrganizationsList = organizations && organizations?.length > 1
  const showFinancialsList = financialFeaturesIsAvailable && !!organizationMenuItems.length
  const showEditOrganizationButton = isAdmin

  return (
    <Menu
      align={align}
      direction={direction}
      transition
      position="initial"
      className={cx('p-0')}
      menuClassName={twMerge(cx('rounded-lg', { '!mt-8 !-ml-8 w-[224px]': !isBurgerMenu }, classes.menu))}
      boundingBoxPadding="1"
      menuButton={({ open }) => (
        <MenuButton
          className={twMerge(
            cx(
              'flex justify-between items-center transition-colors hover:bg-soft_lavender rounded-base py-1 px-1 mx-2 font-medium group',
              {
                [styles.outline]: open,
                'bg-soft_lavender': open,
                'mt-1': isBurgerMenu,
              },
              classes.button,
            ),
          )}
        >
          <CompanyItem
            orgName={org?.name}
            className=""
            wrapperClassName="group-hover:!scale-100"
            labelClassName={cx('transition-margin', classes.companyItem)}
          />
          <IconChevronRight
            className={cx('h-5 w-5 transition-all -translate-x-0.5 group-hover:translate-x-0', {
              'rotate-90 text-brand_primary group-hover:!-translate-x-0.5': open,
            })}
          />
        </MenuButton>
      )}
    >
      <MenuGroup>
        <MenuHeader className={styles.header}>{t('myOrganization')}</MenuHeader>
        {org && (
          <MenuItem
            className={cx(styles.item, 'font-medium text-base justify-between group')}
            onClick={
              showEditOrganizationButton
                ? () => navigate(generatePath(routes.private.organization, { id: org.id }))
                : undefined
            }
          >
            <CompanyItem
              labelClassName={isAdmin ? '' : 'w-40'}
              wrapperClassName="group-hover:!scale-100"
              orgName={org.name}
            />
            {showEditOrganizationButton && (
              <IconPencil className="h-4 w-4 !text-gray-400 group-hover:!text-gray-600 group-hover:scale-105 transition-colors group-active:scale-95" />
            )}
          </MenuItem>
        )}
      </MenuGroup>
      {showFinancialsList && (
        <>
          <MenuDivider />
          <MenuGroup>
            {organizationMenuItems.map((item) => (
              <MenuItem
                className={cx(styles.item, styles.navigationItem, '!pl-3')}
                key={item.label}
                onClick={() => navigate(item.to as string)}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuGroup>
        </>
      )}
      {showOrganizationsList && (
        <>
          <MenuDivider />
          <MenuGroup>
            <MenuHeader className={styles.header}>{t('switchOrganization')}</MenuHeader>
            <div className={cx(styles.hideScroll, isBurgerMenu ? 'max-h-40' : 'max-h-[300px]')}>
              {organizations
                ?.filter((el) => el.id !== organization)
                ?.map((org, index) => {
                  const colorIndex = index % COLORS.length
                  return (
                    <MenuItem className="p-0 m-0 hover:bg-transparent group" key={org.id}>
                      <ButtonWithConfirmation
                        type="contained"
                        className={cx(
                          styles.item,
                          '!min-h-0 !my-0 font-normal !text-black justify-start flex-grow w-auto',
                        )}
                        text={t('switchOrganizationPopupTitle')}
                        subText={t('switchOrganizationPopupText')}
                        onClick={onOrganizationSwitch(org)}
                        shouldConfirm={isStartPipelineRoute || isStartStageRoute}
                        confirmType={ConfirmType.common}
                        confirmText={t('confirmSwitchLabel')}
                      >
                        <CompanyItem
                          labelClassName="!w-40"
                          orgName={org.name}
                          initialsStyles={{ backgroundColor: COLORS[colorIndex] }}
                        />
                      </ButtonWithConfirmation>
                    </MenuItem>
                  )
                })}
            </div>
          </MenuGroup>
        </>
      )}
      {showAddOrganizationButton && (
        <>
          <MenuDivider />
          <MenuGroup>
            <MenuItem
              className={cx(styles.item, styles.navigationItem, 'gap-1 group')}
              onClick={() => navigate(generatePath(routes.private.organization, { id: 'new' }))}
            >
              <IconSquareRoundedPlus size={20} className="group-hover:scale-105" /> {t('newOrganization')}
            </MenuItem>
          </MenuGroup>
        </>
      )}
    </Menu>
  )
}

export default OrganizationMenu
